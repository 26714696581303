import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PLACEHOLDER } from "helpers/constant";
import { logoutUser } from "../../redux/actions/user";
import { Link, useHistory } from "react-router-dom";
import "./notificationBar.css";
import styles from "./newmessages.module.css";
import { notifications as notificationsApi } from "../../api/notifications";
import { fromNow } from "../../helpers/dates";
import Icon from "component/Icon/Icon";
import { getNotification } from "../../redux/actions/notifications";
import RoundImage from "../Image/RoundImage";
import { logoutFromApp, users } from "../../api/users";
import Button from "../Button/Button";
// import styles from "./login.module.css";
import { closeAllChats, setUserChat } from "../../redux/actions/messages";
import { search } from "../../api/search";
import Autocomplete from "./../Autocomplete/Autocomplete";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

const Header = React.memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((store) => store.user.user);
    const notifications = useSelector((store) => store.notifications);
    const [messages, setMessages] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [selectedTab, setSelectedTab] = useState("notifications");
    const [showUserViewMore, setShowUserViewMore] = useState(false);
    const searchForm = useRef();
    const [suggestions, setSuggestions] = useState([]);
    const [query, setQuery] = useState("");
    const [showIntro, setShowIntro] = useState(false);

    const logout = () => {
        dispatch(logoutFromApp())
            .then((response) => {

                if (response.success) {
                    history.push("/login");
                    dispatch(logoutUser());
                    localStorage.clear();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // load notifications
    useEffect(() => {
        if (!user.id) return;
        dispatch(notificationsApi())
            .then((response) => {
                if (response.success) {
                    dispatch(getNotification(response.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, user]);

    useEffect(() => {
        const viewedVideo = window.localStorage.getItem('viewed-intro');

        if (!viewedVideo) {
            setShowIntro(true);
        }
    }, [dispatch])

    const hideNotificationPanel = (e) => {
        setShowNotification(false);

        e.stopPropagation();
    };

    const toggleNotificationPanel = () => {
        setShowNotification((p) => !p);
        dispatch(notificationsApi({ param_path: "view" }))
            .then((response) => {
                if (response.success) {
                    dispatch(getNotification(response.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const toggleshowUserViewMoreSec = () => {
        setShowUserViewMore((p) => !p);
    };

    const renderNotifications = () => {
        return notifications.map((n) => {
            return (
                <li key={n.id}>
                    <figure className={`text-center`}>
                        {" "}
                        <RoundImage src={n.image || PLACEHOLDER} alt="n.title" size="35px" />
                    </figure>
                    <div className={`${styles["mesg-info"]} mesg-info`}>
                        <Link to={n.link}>
                            <p style={{ marginBottom: "0", fontSize: "12px" }}>{n.description}</p>
                        </Link>
                        <small className={`text-info`}>{fromNow(n.created_at)}</small>
                    </div>
                </li>
            );
        });
    };

    const openChat = (id, name, pic) => {
        dispatch(setUserChat(`${id}|${name}`, `${pic}`));
    };

    const renderMessages = () => {
        return messages?.map((m) => {
            const other_user = m.source_id == user.id ? [m.target_id, m.target_name, m.target_pp] : [m.source_id, m.source_name, m.source_pp];

            return (
                <li key={m.id} onClick={() => openChat(other_user[0], other_user[1], other_user[2])}>
                    <figure className={`text-center`}>
                        <RoundImage src={other_user[2] || PLACEHOLDER} size="35px" />
                    </figure>
                    <div className={`${styles["mesg-info"]} ${styles["cursor-pointer"]} mesg-info`}>
                        <span>{other_user[1]}</span>
                        <span className="text-truncate text-muted" href="#" title="">
                            {m.message}
                        </span>
                    </div>
                </li>
            );
        });
    };

    const loadRecentChats = () => {
        dispatch(
            users({
                param_path: "recent_chats",
            }),
        )
            .then((response) => {
                if (response.success) {
                    setMessages(response.data);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getPredictions = (e) => {
        setQuery(e.target.value);
        if (e.target.value.length < 3) {
            setSuggestions([]);
            return;
        }

        dispatch(
            search({
                param_path: "all",
                params: { query: e.target.value },
            }),
        )
            .then((response) => {
                if (response.success) {
                    setSuggestions(response.data);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const selectMessagesTab = () => {
        setSelectedTab("messages");
        loadRecentChats();
    };

    const onSearch = (e) => {
        e.preventDefault();

        if (query.length == 0) {
            return;
        }
        let search_form = new FormData(searchForm.current);

        history.push(`/search/${search_form.get("search")}`);
    };

    const renderSearchHistory = useMemo(() => {
        const renderItem = (item) => {
            return (
                <li>
                    <div className={`searched-user`}>
                        <figure>
                            <img src="/public/images/resources/user1.jpg" alt="" />
                        </figure>
                        <span>Danial Carabal</span>
                    </div>
                    <span className={`trash`}>
                        <Icon name="close-circled" />
                    </span>
                </li>
            );
        };

        return [].map(renderItem);
    }, []);

    const renderUserActions = () => {
        if (user.id) {
            return (
                <>
                    <li>
                        <div className={`user-dp`}>
                            <a>
                                <img alt="" src={`${user?.profile_pic || PLACEHOLDER}`} />
                                <div className={`name mx-2`}>
                                    <h4>{user?.name}</h4>
                                </div>
                            </a>
                            <ul className={`dropdown`} style={{ width: "200px" }}>
                                <li style={{ width: "100%" }}>
                                    <Link to="/profile">
                                        <i className={`icofont-user-alt-3`} /> Your Profile
                                    </Link>
                                </li>
                                <li className={`logout`} onClick={logout}>
                                    <a>
                                        <i className={`icofont-power`} /> Logout
                                    </a>
                                </li>
                                <hr className="mt-1 mb-2" />
                                <div className="d-flex justify-content-end">
                                    <Link className={``} to={`/help-and-support`} style={{ padding: "0px 5px", fontSize: "12px" }}><Icon className={`text-info`} name={`info-circle`}></Icon> Help & Support </Link>
                                </div>
                            </ul>
                        </div>
                    </li>
                    <li>
                        {/* <div className="message-bubble-count">215</div> */}
                        <Link to="/messages" className={`mesg-notif`}>
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={`feather feather-message-square`}
                                >
                                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                </svg>
                            </i>
                        </Link>
                    </li>
                    <li onClick={toggleNotificationPanel} className={`my-notification-li`}>
                        <a className={`mesg-notif`} href="#" title="Notifications" data-toggle="tooltip">
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={`feather feather-bell`}
                                >
                                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
                                    <path d="M13.73 21a2 2 0 0 1-3.46 0" />
                                </svg>
                            </i>
                        </a>
                        {notifications?.length > 0 && notifications[0].seen == 0 && <span />}
                    </li>
                    <li className={`my-notification-li`} onClick={() => setShowIntro(true)}>
                        <a className={`mesg-notif`} href="#" title="Intro" data-toggle="tooltip">
                            <i className="icofont-question">
                                
                            </i>
                        </a>
                        {notifications?.length > 0 && notifications[0].seen == 0 && <span />}
                    </li>
                    <li className={`my-notification-li`}>
                        {showNotification && (
                            <div className={`${styles["side-slide"]} side-slide active`}>
                                <span onClick={hideNotificationPanel} className={`popup-closed`}>
                                    <i className={`icofont-close`}></i>
                                </span>
                                <div className={`slide-meta`}>
                                    <ul className={`nav nav-tabs slide-btns`}>
                                        <li className={`nav-item`} onClick={selectMessagesTab}>
                                            <a className={`${selectedTab == "messages" ? "active" : ""}`} href="#messages" data-toggle="tab">
                                                Messages
                                            </a>
                                        </li>
                                        <li className={`nav-item`} onClick={() => setSelectedTab("notifications")}>
                                            <a className={`${selectedTab == "notifications" ? "active" : ""}`} href="#notifications" data-toggle="tab">
                                                Notifications
                                            </a>
                                        </li>
                                    </ul>
                                    <div className={`tab-content`}>
                                        <div className={`tab-pane fade ${selectedTab == "notifications" ? "active show" : ""}`} id="notifications">
                                            <ul className={`${styles["notificationz"]} notificationz`}>{renderNotifications()}</ul>
                                            <Button type="button" className={`btn-block btn-sm mt-4`} onClick={() => history.replace("/all/notifications")}>
                                                View all
                                            </Button>
                                        </div>
                                        <div className={`tab-pane fade ${selectedTab == "messages" ? "active show" : ""}`} id="messages">
                                            {messages && <ul className={`${styles["new-messages"]} new-messages`}>{renderMessages()}</ul>}
                                            <Button type="button" className={`btn-block btn-sm mt-4`} onClick={() => history.replace("/messages")}>
                                                View all
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </li>
                </>
            );
        } else {
            return (
                <>
                    <Link to="/login">
                        <button className={`btn btn-info btn-sm`} style={{ backgroundColor: "#088dcd" }}>
                            Login <i className={`ml-1 icofont-login`} />
                        </button>
                    </Link>
                </>
            );
        }
    };

    const hideIntroModal = () => {
        window.localStorage.setItem('viewed-intro', true);
        
        setShowIntro(false);
    }

    const renderIntroModal = () => {
        return <Modal size="lg" id="submit-modal" show={showIntro} onHide={hideIntroModal} backdrop="static">
            <Modal.Header className="p-3 border-bottom">
                <h4 className="modal-title">Introduction to Reverie</h4>
                <Button className=" close" type="link" onClick={hideIntroModal}>
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                <video src={window.RAZZLE_CLOUDFRONT_URL + 'reverie_intro.mp4'} controls autoPlay />
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btn-primary d-block" onClick={hideIntroModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    }

    return (
        <>
            {showIntro && renderIntroModal()}
            <div className={`responsive-header`}>
                <div className={`logo res`}>
                    <img src="/public/images/resources/logo.png" alt="" />
                    <span>Reverie</span>
                </div>
                <div className={`d-flex align-items-center `}>
                    {!!user.id == true ? (
                        <>
                            <div className="user-dp-mbl d-flex position-relative mx-2">
                                <Link className={``} to={`/profile/${user?.id}`}>
                                    <RoundImage className={`img-thumbnail`} size={`40px`} src={`${user?.profile_pic || PLACEHOLDER}`} />
                                </Link>
                                <Icon onClick={toggleshowUserViewMoreSec} className={`angle-down user-view-more position-absolute`} name="rounded-down" />
                            </div>
                            <div className="mbl-header-icon-list d-flex">
                                <Link className={``} to={`/all/notifications`}>
                                    <Icon className={`mr-2 rounded-circle border`} name="notification" style={{ padding: "10px" }} />
                                </Link>

                                <Link className={``} to={`/messages/`}>
                                    <Icon className={`rounded-circle border`} name="ui-message" style={{ padding: "10px" }} />
                                </Link>
                            </div>
                        </>
                    ) : (
                        <Link to="/login">
                            <button className={`btn btn-info btn-sm`} style={{ backgroundColor: "#088dcd" }}>
                                Login <i className={`ml-1 icofont-login`} />
                            </button>
                        </Link>
                    )}
                </div>
                {showUserViewMore && (
                    <div className={`user-view-more-sec border p-2`} style={{ width: "140px" }}>
                        <div className="border-bottom">
                            <Link to="/profile">
                                <i className={`icofont-user-alt-3`} /> Your Profile
                            </Link>
                        </div>
                        <div className={`logout`} onClick={logout}>
                            <a>
                                <i className={`text-danger icofont-power`} /> Logout
                            </a>
                        </div>
                    </div>
                )}
            </div>
            {/* responsive header */}
            <header>
                <div className={`${styles["topbar"]} topbar`}>
                    <div className={`logo`}>
                        <Link to="/">
                            <img src="/public/images/resources/logo.png" alt="" />
                            <span>Reverie</span>
                        </Link>
                    </div>
                    {!!user.id == true && <div className={`searches`}>
                        <form ref={searchForm} onSubmit={onSearch}>
                            <Autocomplete suggestions={suggestions} query={query}>
                                <input className="pr-5" maxLength="150" type="text" placeholder="Search by user, hunt, challenge or club" name="search" autoComplete="off" onChange={getPredictions} />
                            </Autocomplete>
                            <button type="submit">
                                <Icon name="search" />
                            </button>
                            {query.trim() !== "" && (
                                <button
                                    style={{
                                        right: "10px",
                                        left: "auto",
                                    }}
                                    type="button"
                                    onClick={() => {
                                        searchForm.current.reset();
                                        setSuggestions([]);
                                        setQuery("");
                                    }}
                                >
                                    <Icon name="close" />
                                </button>
                            )}
                            <span className={`cancel-search`}>
                                <i className={`icofont-close`} />
                            </span>
                            <div className={`recent-search`}>
                                <h4 className={`recent-searches`}>Your's Recent Search</h4>
                                <ul className={`so-history`}>{renderSearchHistory}</ul>
                            </div>
                        </form>
                    </div>}
                    <ul className={`web-elements`}>{renderUserActions()}</ul>
                </div>
            </header>
            {user?.is_eligible?.eligible && user?.subscription_level == 0 && (
                <div className={`p-2 bg-info text-light text-center`}>
                    <Link to={`/profile/edit/${user?.id}/subscription`}>You can enable {`'${user?.is_eligible?.next_rule.name}'`} Click here to enable it.</Link>
                </div>
            )}
            {user?.is_eligible?.eligible && user?.subscription_level == 1 && (
                <div
                    className={`p-2 bg-success
                     text-light text-center`}
                >
                    <Link to={`/profile/edit/${user?.id}/subscription`}>You can enable {`'${user?.is_eligible?.next_rule.name}'`} Click here to enable it.</Link>
                </div>
            )}
        </>
    );
});

export default Header;
